import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import { auth } from "../../services/firebase";
import { FormattedMessage } from "react-intl";

//774kSSPS&x

const style = {
  image: {
    backgroundImage: "url('./img/fondo.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundColor: "grey",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  root: {
    height: "100vh",
  },
  alert: {
    marginTop: "5%",
  },
  paper: {
    margin: "5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "5%",
  },
  submit: {
    marginTop: "5%",
  },
};

class LoginAdmin extends Component {
  state = {
    user: {
      username: "",
      password: "",
    },
    error: "",
  };

  onSubmit(e) {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(
        this.state.user.username,
        this.state.user.password
      )
      .then((res) => {
        console.log("Loggato", res);
        //this.props.onAutenticato = true;
      })
      .catch((error) => {
        this.setState({ error: error.message });
        console.log(error);
      });
  }

  handleChange(e) {
    let user = { ...this.state.user };
    user[e.target.name] = e.target.value;
    this.setState({ user });
  }

  render() {
    return (
      <Grid container component="main" style={style.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} style={style.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div style={style.paper}>
            <Typography component="h1" variant="h5">
              Formula Bici - Ride Your Dream ADMIN
            </Typography>
            {this.state.error ? (
              <Alert style={style.alert} severity="error">
                {this.state.error}
              </Alert>
            ) : null}
            <form
              style={style.form}
              noValidate
              onSubmit={(e) => this.onSubmit(e)}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                //defaultValue="gerevini@formulabici.it"
                onChange={(e) => this.handleChange(e)}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                type="password"
                required
                fullWidth
                id="password"
                label="password"
                name="password"
                //defaultValue="774kSSPS&x"
                onChange={(e) => this.handleChange(e)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={style.submit}
              >
                <FormattedMessage id="login.login" />
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default LoginAdmin;
