import axios from "axios";
import { db } from "./firebase";
import { fb } from "./firebase";

export async function verificaUtente(registrationcode, rememberme) {
  //configurazioni njuko
  let config = {
    headers: {
      Edition: "18060",
      Authorization: "ab32d048693703228b375db0e972f047",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  let data = {
    itemsPerPage: 1,
    answers: true,
    search: {
      registrationCode: registrationcode,
    },
  };

  //verifico se presente l'utente è presente in DB!
  const userRef = db.collection("utenti").doc(registrationcode);
  return userRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        //console.log("user found in db");
        let user = doc.data();
        return Promise.resolve(user.utente);
      } else {
        //altrimenti chiamo njuko
        //chiamata a Njuko per verificare Login
        console.log("njuko api call");
        return axios
          .post(
            `https://api.njuko.net/api-registrations/registrations`,
            data,
            config
          )
          .then((res) => {
            //controllo che venga trovato regid
            if (res.data.resultsSize < 1) {
              return Promise.reject("login.error2");
            }
            //controllo pagamento
            if (!res.data.results[0].isRegistered) {
              return Promise.reject("login.error3");
            }

            //se tutto ok autentico utente
            let user = {
              nome: res.data.results[0].firstname,
              cognome: res.data.results[0].lastname,
              email: res.data.results[0].email,
              dataDiNascita: bonificaDataDiNascita(
                res.data.results[0].birthdate
              ),
              registrationcode: res.data.results[0].registrationCode,
              autenticato: true,
              lingua: res.data.results[0].language === 7 ? "it" : "en",
              dataIscrizione: new Date(res.data.results[0].created * 1000),
              nazione: res.data.results[0].answers[0].values_array.cioCode,
            };
            return user;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
    })
    .catch((err) => {
      //console.log(err);
      return Promise.reject(err);
    });
}

function bonificaDataDiNascita(data) {
  return new Date(
    String(data).substr(0, 4),
    String(data).substr(4, 2) - 1,
    String(data).substr(6, 2),
    0,
    0,
    0,
    0
  );
}

//server per il login su firebase - per usare storage etc
export function loginUtente(utente) {
  fb.auth()
    .signInAnonymously()
    .catch(function (error) {
      // Handle Errors here.
      var errorMessage = error.message;
      console.log(errorMessage);
      // ...
    });
}

export function creaUtente(utente) {
  //console.log("creo utente", utente);
  const userRef = db.collection("utenti").doc(utente.registrationcode);
  userRef.set({
    utente,
  });
}
