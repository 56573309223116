import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const ConfermaSalvataggio = (props) => {
  return (
    <Dialog
      open={props.mostraConferma}
      onClose={() => props.onCloseConferma()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id="module.confirm"></FormattedMessage>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage id="module.confirmmessage"></FormattedMessage>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.onCloseConferma()}
          color="primary"
          autoFocus
        >
          <FormattedMessage id="module.close"></FormattedMessage>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfermaSalvataggio;
