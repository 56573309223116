import React, { Component } from "react";
import "./App.css";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Login from "./components/Login";
import Cookies from "universal-cookie";
import BarraNavigazione from "./components/BarraNavigazione";
import Modulo from "./components/Modulo";
import { IntlProvider } from "react-intl";
import messages_it from "./traduzioni/it.json";
import messages_en from "./traduzioni/en.json";
import { creaUtente, loginUtente } from "./services/users";
import { Switch, Route } from "react-router-dom";
import LeMieGf from "./components/LeMieGf";
import MainAdmin from "./components/admin/MainAdmin";

const messages = {
  en: messages_en,
  it: messages_it,
};

//const language = navigator.language.split(/[-_]/)[0];

const style = {
  image: {
    backgroundImage: "url('./img/fondo.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundColor: "grey",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
  },
  cont_modulo: {
    marginLeft: "auto",
    marginRight: "auto",
  },
};

class App extends Component {
  state = {
    loading: false,
    user: {
      nome: "",
      cognome: "",
      email: "",
      autenticato: false,
      registrationcode: "",
      dataIscrizione: "",
    },
    lingua: navigator.language.split(/[-_]/)[0],
    //lingua: "it",
  };

  cambiaLingua(lingua) {
    console.log("cambio lingua", lingua);
    this.setState({ lingua });
  }

  autenticaUtente(user, rememberme) {
    //se remember me setto storage
    //scade tra 1 mese
    let data = new Date();
    data.setMonth(data.getMonth() + 1);

    if (rememberme) {
      const cookies = new Cookies();
      cookies.set("sessid", user.registrationcode, {
        path: "/",
        expires: data,
      });
    }
    this.setState({ user });
    creaUtente(user);
    //per interagire con fb
    loginUtente(user);
  }

  logout() {
    console.log("logout");
    const cookies = new Cookies();
    cookies.remove("sessid");
    let user = this.state.user;
    user.autenticato = false;
    this.setState({ user });
  }

  render() {
    return !this.state.user.autenticato ? (
      <IntlProvider
        locale={this.state.lingua}
        messages={messages[this.state.lingua]}
      >
        <Switch>
          <Route path="/admin">
            <MainAdmin />
          </Route>
          <Route path="/">
            <Login
              user={this.state.user}
              onAutenticato={(user, rememberme) =>
                this.autenticaUtente(user, rememberme)
              }
              lingua={this.state.lingua}
              onCambioLingua={(lingua) => this.cambiaLingua(lingua)}
            />
          </Route>
        </Switch>
      </IntlProvider>
    ) : (
      <IntlProvider
        locale={this.state.lingua}
        messages={messages[this.state.lingua]}
      >
        <div className="App">
          <BarraNavigazione
            user={this.state.user}
            lingua={this.state.lingua}
            onCambioLingua={(lingua) => this.cambiaLingua(lingua)}
            onLogout={() => this.logout()}
          ></BarraNavigazione>
          <Switch>
            <Route exact path="/lemiegf/:msg?">
              <Grid container style={style.image}>
                <CssBaseline />
                <Grid item xs={12} sm={10} md={10} style={style.cont_modulo}>
                  <LeMieGf user={this.state.user}></LeMieGf>
                </Grid>
              </Grid>
            </Route>
            <Route exact path="/">
              <Grid container style={style.image}>
                <CssBaseline />
                <Grid item xs={12} sm={10} md={10} style={style.cont_modulo}>
                  <Modulo
                    lingua={this.state.lingua}
                    user={this.state.user}
                  ></Modulo>
                </Grid>
              </Grid>
            </Route>
          </Switch>
        </div>
      </IntlProvider>
    );
  }
}

export default App;
