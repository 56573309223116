import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { FormattedMessage } from "react-intl";

const ListaGranfondo = (props) => {
  return (
    <Autocomplete
      id="combo-box-granfondo"
      options={props.granfondo}
      getOptionLabel={(option) => option.nome}
      style={{ width: "100%" }}
      required
      onChange={(e, val) => props.onCambioGranfondo(e, val)}
      renderInput={(params) => (
        <TextField
          {...params}
          error={props.error}
          label={<FormattedMessage id="selectgf.label"></FormattedMessage>}
          variant="outlined"
        />
      )}
    />
  );
};

export default ListaGranfondo;
