import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import HelpIcon from "@material-ui/icons/Help";

const HelpDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <HelpIcon
        fontSize="large"
        color="primary"
        onClick={() => handleClickOpen()}
        style={{ cursor: "pointer" }}
      ></HelpIcon>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id="help.title"></FormattedMessage>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage id={props.message}></FormattedMessage>
            <br />
            <img alt="example" src={props.img}></img>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            <FormattedMessage id="module.close"></FormattedMessage>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HelpDialog;
