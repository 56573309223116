import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { createIntl, FormattedMessage, createIntlCache } from "react-intl";
import { db } from "../services/firebase";
import { validaUscita, validaCampoUscita } from "../services/validation";
import ListaGranfondo from "./ListaGranfondo";
import {
  Box,
  Divider,
  Button,
  Chip,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SpeedIcon from "@material-ui/icons/Speed";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import FileUpload from "./FileUpload";
import SceltaLocalita from "./SceltaLocalita";
import ConfermaSalvataggio from "./ConfermaSalvataggio";
import HelpDialog from "./HelpDialog";
import Alert from "@material-ui/lab/Alert";
import TempoUscita from "./TempoUscita";
import SceltaData from "./SceltaData";
import { withRouter } from "react-router-dom";

//import { DatePicker } from "@material-ui/pickers";

const cache = createIntlCache();
const intl = createIntl({ locale: navigator.language.split(/[-_]/)[0] }, cache);

class Modulo extends Component {
  state = {
    user: this.props.user,
    uscita: {
      user: this.props.user,
      granfondo: {},
      distanza: "",
      dislivello: "",
      localitapartenza: {},
      tempo: "",
      foto1: "",
      filegiro: "",
      filegiro2: "",
      fontefbryd: "",
      dataInserimento: new Date(),
      dataUscita: "",
      stato: "nonconfermata",
    },
    dataUscitaDatePicker: null,
    tempoDatePicker: this.tempoIniziale(),
    errors: [],
    granfondo: [],
    distanzaGf: "",
    dislivelloGf: "",
    specialitaGf: "",
    logoGF: "",
    mostraConferma: false,
  };

  componentDidMount() {
    let granfondo = [];
    //carico lista granfondo
    db.collection("/granfondo")
      .orderBy("nome")
      .get()
      .then((res) => {
        res.forEach(function (gf) {
          let gfconid = gf.data();
          gfconid.id = gf.id;
          granfondo.push(gfconid);
        });
        this.setState({ granfondo });
      });
  }

  chiudiConferma() {
    this.setState({ mostraConferma: false });
    const { history } = this.props;
    history.push("/lemiegf");
    //reset form?
  }

  //aggiorno valori da form a state
  cambioGranfondo(e, gf) {
    const error = validaCampoUscita("granfondo", gf);
    this.impostaStatoErrrori(error, "granfondo");

    if (gf) {
      let uscita = { ...this.state.uscita };
      //console.log(gf.nome);
      uscita.granfondo = gf;
      let distanzaGf = gf.distanza;
      let dislivelloGf = gf.dislivello;
      let specialitaGf = gf.specialita;
      let logoGF = "./img/loghigf/" + gf.logo;
      this.setState({ uscita, distanzaGf, dislivelloGf, specialitaGf, logoGF });
    }
  }

  cambioLocalita(localitapartenza) {
    //validazione campo
    const error = validaCampoUscita("localitapartenza", localitapartenza);
    //console.log(error);
    this.impostaStatoErrrori(error, "localitapartenza");

    let uscita = { ...this.state.uscita };
    uscita.localitapartenza = localitapartenza;
    this.setState({ uscita });
  }

  tempoIniziale() {
    let data = new Date();
    data.setHours(0);
    data.setMinutes(0);
    data.setSeconds(0);
    return data;
  }

  cambioTempo(tempo) {
    console.log(tempo);
    let uscita = { ...this.state.uscita };
    uscita.tempo =
      this.pad(tempo.c.hour, 2) +
      ":" +
      this.pad(tempo.c.minute, 2) +
      ":" +
      this.pad(tempo.c.second, 2);
    this.setState({ uscita, tempoDatePicker: tempo });
  }

  pad(n, width, z) {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  cambioData(dataUscita) {
    //console.log(dataUscita.ts);
    if (dataUscita) {
      let uscita = { ...this.state.uscita };
      let dataUscitaDatePicker = dataUscita;
      uscita.dataUscita = new Date(dataUscita.ts);
      this.setState({ uscita, dataUscitaDatePicker });
    }
  }

  impostaStatoErrrori(error, campo) {
    if (error) {
      let errors = { ...this.state.errors };
      errors[campo] = true;
      this.setState({ errors });
      return;
    } else {
      let errors = { ...this.state.errors };
      delete errors[campo];
      this.setState({ errors });
    }
  }

  onChangeValue(e) {
    //validazione campo
    const error = validaCampoUscita(e.target.name, e.target.value);
    //console.log(error);
    this.impostaStatoErrrori(error, e.target.name);
    //if all correct set uscita
    let uscita = { ...this.state.uscita };
    uscita[e.target.name] = e.target.value;
    this.setState({ uscita });
  }

  //se i file sono caricati correttamente setto lo stato
  fileUploaded(id, file) {
    let uscita = { ...this.state.uscita };
    uscita[id] = file;
    this.setState({ uscita });
  }

  submit() {
    const errors = validaUscita(this.state.uscita);
    if (errors) {
      //console.log(errors);
      this.setState({ errors });
      return;
    }

    //se tutto ok SALVO I DATI IN DB
    db.collection("uscite")
      .doc()
      .set(this.state.uscita)
      .then((res) => {
        console.log("uscita aggiunta", res);
        this.setState({ mostraConferma: true });
      });
  }

  reset() {
    const uscita = {
      granfondo: {},
      distanza: "",
      dislivello: "",
      localitapartenza: {},
      tempo: "",
      foto1: "",
      filegiro: "",
      fontefbryd: "",
    };
    this.setState({ uscita });
  }

  render() {
    return (
      <main style={style.layout}>
        <Paper style={style.paper}>
          <Typography component="h1" variant="h4" align="center">
            <FormattedMessage id="module.title"></FormattedMessage>
          </Typography>
          <form>
            <Grid mt={10} container spacing={4}>
              <Box m={5}></Box>
              <Grid item xs={12}>
                <Typography component="h2" color="primary">
                  <FormattedMessage id="module.titleanag"></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  disabled
                  id="nome"
                  name="nome"
                  value={this.state.user.nome}
                  label={
                    <FormattedMessage id="module.firstname"></FormattedMessage>
                  }
                  fullWidth
                  autoComplete="fname"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  disabled
                  id="cognome"
                  name="cognome"
                  value={this.state.user.cognome}
                  label={
                    <FormattedMessage id="module.lastname"></FormattedMessage>
                  }
                  fullWidth
                  autoComplete="lname"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  disabled
                  id="dataiscrizione"
                  name="dataiscrizione"
                  value={
                    this.state.user.dataIscrizione.seconds
                      ? intl.formatDate(
                          new Date(
                            this.state.user.dataIscrizione.seconds * 1000
                          )
                        )
                      : ""
                  }
                  label={
                    <FormattedMessage id="module.registrationdate"></FormattedMessage>
                  }
                  fullWidth
                  autoComplete="dbirth"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  disabled
                  id="email"
                  name="email"
                  value={this.state.user.email}
                  label={
                    <FormattedMessage id="module.email"></FormattedMessage>
                  }
                  fullWidth
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="h2" color="primary">
                  <FormattedMessage id="module.titlegf"></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <ListaGranfondo
                  granfondo={this.state.granfondo}
                  error={this.state.errors["granfondo"]}
                  onCambioGranfondo={(e, val) => this.cambioGranfondo(e, val)}
                ></ListaGranfondo>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box
                  display="flex"
                  justifyContent="center"
                  bgcolor="background.paper"
                >
                  <Box pt={1}>
                    {/* {this.state.logoGF ? (
                      <img
                        src={this.state.logoGF}
                        style={style.logogf}
                        alt="logogf"
                      ></img>
                    ) : null} */}
                    {this.state.distanzaGf ? (
                      <Chip
                        color="primary"
                        icon={<SpeedIcon />}
                        label={this.state.distanzaGf + " Km"}
                      />
                    ) : null}
                    &nbsp; &nbsp;
                    {this.state.dislivelloGf ? (
                      <Chip
                        color="primary"
                        icon={<AssessmentIcon />}
                        label={this.state.dislivelloGf + " m. DSL+"}
                      />
                    ) : null}
                    &nbsp; &nbsp;
                    {this.state.specialitaGf ? (
                      <Chip
                        color="primary"
                        icon={<DirectionsBikeIcon />}
                        label={this.state.specialitaGf}
                      />
                    ) : null}
                  </Box>
                </Box>
              </Grid>
              <Divider variant="middle" />
              <Grid item xs={12}>
                <Typography component="h2" color="primary">
                  <FormattedMessage id="module.titleride"></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="distanza"
                  name="distanza"
                  error={this.state.errors["distanza"]}
                  type="number"
                  label={
                    <FormattedMessage id="module.distance"></FormattedMessage>
                  }
                  fullWidth
                  autoComplete="fname"
                  onChange={(e) => this.onChangeValue(e)}
                  value={this.state.uscita.distanza}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="dislivello"
                  name="dislivello"
                  error={this.state.errors["dislivello"]}
                  type="number"
                  label={
                    <FormattedMessage id="module.dislivello"></FormattedMessage>
                  }
                  fullWidth
                  autoComplete="lname"
                  onChange={(e) => this.onChangeValue(e)}
                  value={this.state.uscita.dislivello}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SceltaLocalita
                  error={this.state.errors["localitapartenza"]}
                  onCambioLocalita={(localitapartenza) =>
                    this.cambioLocalita(localitapartenza)
                  }
                ></SceltaLocalita>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TempoUscita
                  id="tempo"
                  error={this.state.errors["tempo"]}
                  value={this.state.tempoDatePicker}
                  handleDateChange={(e) => this.cambioTempo(e)}
                ></TempoUscita>
              </Grid>
              <Grid xs={12} sm={6}>
                <SceltaData
                  error={this.state.errors["dataUscita"]}
                  value={this.state.dataUscitaDatePicker}
                  onCambioData={(data) => this.cambioData(data)}
                ></SceltaData>
              </Grid>
              <Grid xs={12} sm={6}>
                {/* spazio vuoto */}
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography component="h2" color="primary">
                  <FormattedMessage id="module.titlephotos"></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <HelpDialog message="help.foto1" img="/img/example1.jpg" />
              </Grid>
              <Grid item xs={12}>
                <FileUpload
                  onFileUploaded={(id, file) => this.fileUploaded(id, file)}
                  id="foto1"
                  required
                  error={this.state.errors["foto1"]}
                  user={this.state.user}
                ></FileUpload>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography component="h2" color="primary">
                  <FormattedMessage id="module.titlephotosroute"></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <HelpDialog message="help.filegiro" img="/img/example2.png" />
              </Grid>
              <Grid item xs={12}>
                <FileUpload
                  onFileUploaded={(id, file) => this.fileUploaded(id, file)}
                  id="filegiro"
                  error={this.state.errors["filegiro"]}
                  required
                  user={this.state.user}
                ></FileUpload>
              </Grid>
              <Grid item xs={12}>
                <FileUpload
                  onFileUploaded={(id, file) => this.fileUploaded(id, file)}
                  id="filegiro2"
                  error={this.state.errors["filegiro2"]}
                  user={this.state.user}
                ></FileUpload>
              </Grid>
              <Grid item xs={12}>
                <Typography component="h2" color="primary">
                  <FormattedMessage id="module.finaltext"></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <InputLabel id="fontefbryd">
                  <FormattedMessage id="module.fontefbryd"></FormattedMessage>
                </InputLabel>
              </Grid>
              <Grid item md={6} xs={12}>
                <Select
                  autoWidth={false}
                  labelId="fontefbryd"
                  id="fontefbryd"
                  name="fontefbryd"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={this.state.uscita.fontefbryd}
                  onChange={(e) => this.onChangeValue(e)}
                >
                  <MenuItem value="fbevento">
                    Pagina social di un evento
                  </MenuItem>
                  <MenuItem value="fbfb">Pagina social di FormulaBici</MenuItem>
                  <MenuItem value="media">Media di settore</MenuItem>
                  <MenuItem value="amici">Amici o conoscenti</MenuItem>
                  <MenuItem value="newsletter">Newsletter</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                {Object.keys(this.state.errors).length > 0 ? (
                  <Box m={5}>
                    <Alert severity="error">
                      <FormattedMessage id="module.error"></FormattedMessage>
                    </Alert>
                  </Box>
                ) : null}
                <Button
                  align="center"
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => this.submit()}
                >
                  <FormattedMessage id="module.submit"></FormattedMessage>
                </Button>
                <ConfermaSalvataggio
                  uscita={this.state.uscita}
                  mostraConferma={this.state.mostraConferma}
                  onCloseConferma={() => this.chiudiConferma()}
                ></ConfermaSalvataggio>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </main>
    );
  }
}

const style = {
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: "5%",
    marginRight: "5%",
  },
  paper: {
    marginTop: "5%",
    marginBottom: "5%",
    padding: "5%",
  },
  icon: {
    cursor: "pointer",
  },
  logogf: {
    width: "50px",
    height: "50px",
  },
};

export default withRouter(Modulo);
