import React from "react";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { FormattedMessage } from "react-intl";

const TempoUscita = (props) => {
  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <TimePicker
        style={{ width: "100%" }}
        ampm={false}
        openTo="hours"
        views={["hours", "minutes", "seconds"]}
        format="HH:mm:ss"
        error={props.error}
        invalidDateMessage={
          <FormattedMessage id="module.tempoerrato"></FormattedMessage>
        }
        label={<FormattedMessage id="module.tempo"></FormattedMessage>}
        value={props.value}
        onChange={(e) => props.handleDateChange(e)}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TempoUscita;
