import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Route, Link, Redirect } from "react-router-dom";
import { Box } from "@material-ui/core";
import DettaglioUscita from "./DettaglioUscita";
import Paper from "@material-ui/core/Paper";
import { auth } from "../../services/firebase";
import Uscite from "./Uscite";
import { withRouter } from "react-router-dom";

class AdminPage extends Component {
  constructor(props) {
    super();
    this.state = {
      uscite: [],
      loading: true,
    };
  }

  logout() {
    auth.signOut();
    const { history } = this.props;
    history.push("/");
  }

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar
          position="static"
          color="default"
          elevation={0}
          style={style.appBar}
        >
          <Toolbar style={style.toolbar}>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              style={style.toolbarTitle}
            >
              Ciao {this.props.user.nomeUtente}
            </Typography>
            <nav style={style.links}>
              <Link
                variant="button"
                color="textPrimary"
                to="/admin/uscite"
                style={style.link}
              >
                Tutte le Uscite
              </Link>
              <Link
                variant="button"
                color="textPrimary"
                to="/admin/uscitedaconfermare"
                style={style.link}
              >
                Uscite da Confermare
              </Link>
              <Link
                variant="button"
                color="textPrimary"
                to="/admin/listautenti"
                style={style.link}
              >
                Utenti
              </Link>
            </nav>
            <Button
              color="primary"
              variant="outlined"
              style={style.link}
              onClick={() => this.logout()}
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Container component="main" style={style.heroContent}>
          <Box mt={5}>
            <Paper elevation={2}>
              <Box p={5}>
                <Route
                  exact
                  path="/admin/uscita/dettaglio/:iduscita"
                  component={DettaglioUscita}
                />
                <Route exact path="/admin/listautenti">
                  utenti pagina da implementare
                </Route>
                <Route path="/admin/uscitedaconfermare">
                  <Uscite uscite="daconfermare" />
                </Route>
                <Route path="/admin/uscite">
                  <Uscite uscite="all" />
                </Route>
                <Redirect path="/admin" to="/admin/uscite"></Redirect>
              </Box>
            </Paper>
          </Box>
        </Container>
      </React.Fragment>
    );
  }
}

const style = {
  links: {
    flexGrow: 1,
  },
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: "1px solid gray",
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    textDecoration: "none",
    width: "100px",
    margin: "3%",
  },
};

export default withRouter(AdminPage);
