import React, { Component } from "react";
import LoginAdmin from "./LoginAdmin";
import AdminPage from "./AdminPage";
import { auth } from "../../services/firebase";
import { CircularProgress } from "@material-ui/core";

class MainAdmin extends Component {
  state = {
    loading: true,
    user: {
      autenticato: false,
      uid: "",
      email: "",
      nomeUtente: "",
    },
  };

  componentDidMount() {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth && userAuth.email) {
        //console.log("autenticato : ", userAuth);
        this.setState({
          user: {
            autenticato: true,
            uid: userAuth.uid,
            email: userAuth.email,
            nomeUtente: userAuth.displayName
              ? userAuth.displayName
              : userAuth.email,
          },
          loading: false,
        });
      } else {
        this.setState({
          user: {
            autenticato: false,
          },
          loading: false,
        });
      }
    });
  }

  render() {
    return this.state.loading ? (
      <CircularProgress />
    ) : this.state.user.autenticato ? (
      <AdminPage user={this.state.user} />
    ) : (
      <LoginAdmin />
    );
  }
}

export default MainAdmin;
