import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import SelectLingua from "./SelectLingua";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const BarraNavigazione = (props) => {
  const history = useHistory();
  const classes = useStyles();

  function handleClick() {
    history.push("/lemiegf");
  }

  function handleClickHome() {
    history.push("/");
  }

  return (
    <AppBar
      title={<img alt="logofb" src="/img/rideyourdreamlogo.jpg" />}
      position="absolute"
      color="default"
      className={classes.appBar}
    >
      <Toolbar>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          noWrap
        >
          FormulaBici RideYourDream
        </Typography>
        <Box mr={4}>
          <SelectLingua
            lingua={props.lingua}
            onCambioLingua={(lingua) => props.onCambioLingua(lingua)}
          ></SelectLingua>
        </Box>
        <Button
          className={classes.buttonnuovagf}
          color="primary"
          onClick={handleClickHome}
        >
          <FormattedMessage id="navbar.nuovagf" />
        </Button>
        <Button
          className={classes.buttongf}
          color="primary"
          onClick={handleClick}
        >
          <FormattedMessage id="navbar.lemiegf" />
        </Button>
        <Typography>
          <FormattedMessage id="navbar.welcome" />
          &nbsp;
          {props.user.nome}
        </Typography>
        <Button
          className={classes.button}
          onClick={() => props.onLogout()}
          color="inherit"
        >
          <FormattedMessage id="navbar.logout" />
        </Button>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    flexGrow: 1,
  },
  button: {
    marginLeft: "5%",
  },
  buttongf: {
    marginRight: "5%",
    marginLeft: "2%",
  },
  buttonnuovagf: {
    marginRight: "1%",
    marginLeft: "1%",
  },
}));

export default BarraNavigazione;
