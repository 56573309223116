import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

const ConfirmDialog = (props) => {
  //const [open, setOpen] = React.useState(false);
  /*
  const handleClickOpen = () => {
    setOpen(true);
  };
*/

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.handleNo()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Sei sicuro di voler procedere?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Messaggio
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleNo()} color="primary" autoFocus>
            No
          </Button>
          <Button onClick={() => props.handleSi()} color="primary" autoFocus>
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;
