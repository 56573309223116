import React, { Component } from "react";
import { db, storage } from "../../services/firebase";
import { CircularProgress, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { FormattedDate } from "react-intl";
import ConfirmDialog from "./ConfirmDialog";
import StatoDiploma from "./StatoDiploma";

class DettaglioUscita extends Component {
  constructor(props) {
    super();
    this.state = {
      iduscita: "",
      uscita: {},
      loading: true,
      apriConfermaEliminazione: false,
      apriConfermaStato: false,
      diploma: "",
      errorediploma: "",
    };
  }

  componentDidMount() {
    const { iduscita } = this.props.match.params;
    this.setState({ iduscita });

    db.collection("uscite")
      .doc(iduscita)
      .get()
      .then((doc) => {
        let uscita = doc.data();
        //CERCO DI caricare il diploma se l'uscita è confermata
        if (uscita.stato === "confermata") {
          const nomefile = `/diplomi/${uscita.user.registrationcode}/${uscita.granfondo.nome}.pdf`;
          console.log(nomefile);
          storage
            .ref(nomefile)
            .getDownloadURL()
            .then((url) => {
              //console.log("Got download url: ", url);
              this.setState({ diploma: url });
            })
            .catch((err) => {
              this.setState({ errorediploma: err.message });
            });
        }
        this.setState({ uscita, loading: false });
      });
  }

  onEliminaUscita() {
    this.setState({ apriConfermaEliminazione: true });
  }
  onConfermaUscita() {
    this.setState({ apriConfermaStato: true });
  }

  procedi(funzione) {
    console.log(funzione);
    this[funzione]();
    this.setState({ apriConfermaEliminazione: false });
  }

  chiudi(dialog) {
    console.log("chiudi", dialog);
    this.setState({ [dialog]: false });
  }

  generaDiploma() {
    console.log("genero diploma di nuovo");
    let url =
      "https://europe-west3-rideyourdreamfb.cloudfunctions.net/rigeneraDiploma?iduscita=";

    if (window.location.hostname === "localhost") {
      url =
        "http://localhost:5001/rideyourdreamfb/europe-west3/rigeneraDiploma?iduscita=";
    }

    fetch(url + this.state.iduscita, {
      method: "get",
      dataType: "json",
    })
      .then((response) => response.json())
      .then((responseJSON) => {
        // do stuff with responseJSON here...
        console.log(responseJSON);
        this.setState({ diploma: responseJSON.urldiploma, errorediploma: "" });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ errorediploma: err.message });
      });
    //chiamo funzione http che genera diploma
  }

  confermaUscita() {
    const { iduscita } = this.state;
    let uscita = { ...this.state.uscita };
    uscita.stato = "confermata";
    uscita.dataConferma = new Date();

    db.collection("uscite")
      .doc(iduscita)
      .set(uscita)
      .then((res) => {
        //redirect
        this.setState({ uscita });
        this.props.history.push("/admin/uscite");
      });
  }

  eliminaUscita() {
    const { iduscita } = this.state;

    db.collection("uscite")
      .doc(iduscita)
      .delete()
      .then((res) => {
        this.props.history.push("/admin/uscite");
        //redirect
      });
  }

  checkDistanza(distanzaPercorsa, distanzaGranfondo) {
    const differenza = Math.round(
      (Number.parseInt(distanzaPercorsa) / Number.parseInt(distanzaGranfondo) -
        1) *
        100
    );

    if (differenza > 10 || differenza < -10) {
      return <b style={{ color: "red" }}>{differenza}</b>;
    } else {
      return differenza;
    }
  }

  render() {
    const { uscita, loading } = this.state;
    return (
      <Box p={5}>
        <Typography variant="h2" gutterBottom>
          Dettaglio Uscita
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid container>
            <Grid item md={6}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <b>Codice Registrazione : </b>
                </Grid>
                <Grid item md={6}>
                  {uscita.user.registrationcode}
                </Grid>
                <Grid item md={6}>
                  <b>Data Iscrizione utente : </b>
                </Grid>
                <Grid item md={6}>
                  {uscita.user.dataIscrizione ? (
                    <FormattedDate
                      value={uscita.user.dataIscrizione.seconds * 1000}
                    ></FormattedDate>
                  ) : null}
                </Grid>
                <Grid item md={6}>
                  <b>Data Uscita : </b>
                </Grid>
                <Grid item md={6}>
                  {uscita.dataUscita ? (
                    <FormattedDate
                      value={uscita.dataUscita.seconds * 1000}
                    ></FormattedDate>
                  ) : null}
                </Grid>
                <Grid item md={6}>
                  <b>Nome : </b>
                </Grid>
                <Grid item md={6}>
                  {uscita.user.nome}
                </Grid>
                <Grid item md={6}>
                  <b>Cognome : </b>
                </Grid>
                <Grid item md={6}>
                  {uscita.user.cognome}
                </Grid>
                <Grid item md={6}>
                  <b>Email : </b>
                </Grid>
                <Grid item md={6}>
                  {uscita.user.email}
                </Grid>
                <Grid item md={6}>
                  <b>Lingua : </b>
                </Grid>
                <Grid item md={6}>
                  {uscita.user.lingua}
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <b>Data Inserimento uscita : </b>
                </Grid>
                {uscita.dataInserimento ? (
                  <Grid item md={6}>
                    <FormattedDate
                      value={uscita.dataInserimento.seconds * 1000}
                    ></FormattedDate>
                  </Grid>
                ) : null}
                <Grid item md={6}>
                  <b>Granfondo : </b>
                </Grid>
                <Grid item md={6}>
                  {uscita.granfondo.nome}
                </Grid>
                <Grid item md={6}>
                  <b>Dist/Dislivello Granfondo : </b>
                </Grid>
                <Grid item md={6}>
                  {uscita.granfondo.distanza} km, {uscita.granfondo.dislivello}
                  mt
                </Grid>
                <Grid item md={6}>
                  <b>Distanza Percorsa : </b>
                </Grid>
                <Grid item md={6}>
                  {uscita.distanza} km (
                  {this.checkDistanza(
                    uscita.distanza,
                    uscita.granfondo.distanza
                  )}
                  %)
                </Grid>
                <Grid item md={6}>
                  <b>Dislivello Scalato : </b>
                </Grid>
                <Grid item md={6}>
                  {uscita.dislivello} mt (
                  {this.checkDistanza(
                    uscita.dislivello,
                    uscita.granfondo.dislivello
                  )}
                  %)
                </Grid>
                <Grid item md={6}>
                  <b>Tempo uscita: </b>
                </Grid>
                <Grid item md={6}>
                  {uscita.tempo}
                </Grid>
                <Grid item md={6}>
                  <b>Localita partenza: </b>
                </Grid>
                <Grid item md={6}>
                  {uscita.localitapartenza.description}
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item md={6}>
                <b>Foto 1: </b>
              </Grid>
              <Grid item md={6}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={uscita.foto1}
                >
                  <img
                    style={{
                      maxWidth: "200px",
                      height: "auto",
                      marginTop: "5%",
                    }}
                    src={uscita.foto1}
                    alt="foto1"
                  ></img>
                </a>
              </Grid>
            </Grid>

            <Grid container spacing={3} md={12}>
              {uscita.filegiro ? (
                <React.Fragment>
                  <Grid item md={6}>
                    <b>File Uscita 1: </b>
                  </Grid>
                  <Grid item md={6}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={uscita.filegiro}
                    >
                      Scarica ora
                    </a>
                  </Grid>
                </React.Fragment>
              ) : null}
              {uscita.filegiro2 ? (
                <React.Fragment>
                  <Grid item md={6}>
                    <b>File Uscita 2: </b>
                  </Grid>
                  <Grid item md={6}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={uscita.filegiro2}
                    >
                      Scarica ora
                    </a>
                  </Grid>
                </React.Fragment>
              ) : null}

              <Grid item md={6}>
                <b>Stato uscita: </b>
              </Grid>
              <Grid item md={6}>
                {uscita.stato === "nonconfermata" ? (
                  <b style={{ color: "red" }}>NON CONFERMATA</b>
                ) : (
                  <b style={{ color: "green" }}>CONFERMATA</b>
                )}
              </Grid>
              <Grid item md={6}>
                <b>Diploma: </b>
              </Grid>
              <Grid item md={6}>
                <StatoDiploma iduscita={this.state.iduscita}></StatoDiploma>
              </Grid>
              <Grid item md={4}>
                <ConfirmDialog
                  open={this.state.apriConfermaStato}
                  //handleNo={() => this.chiudi("apriConfermaStato")}
                  handleNo={() => this.chiudi("apriConfermaStato")}
                  handleSi={() => this.procedi("confermaUscita")}
                ></ConfirmDialog>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.onConfermaUscita()}
                >
                  Conferma uscita
                </Button>
              </Grid>
              <Grid item md={4}>
                {uscita.stato === "confermata" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.generaDiploma()}
                  >
                    Ri-Genera Diploma
                  </Button>
                ) : null}
              </Grid>
              <Grid item md={4}>
                <ConfirmDialog
                  open={this.state.apriConfermaEliminazione}
                  handleNo={() => this.chiudi("apriConfermaEliminazione")}
                  handleSi={() => this.procedi("eliminaUscita")}
                ></ConfirmDialog>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.onEliminaUscita()}
                >
                  Elimina uscita
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
}

export default DettaglioUscita;
