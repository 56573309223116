const Joi = require("joi");

const schemaUscita = {
  user: Joi.optional(),
  granfondo: Joi.object().min(1),
  distanza: Joi.number().max(999).min(0).required(),
  dislivello: Joi.number().required(),
  localitapartenza: Joi.object().min(1),
  tempo: Joi.string()
    .regex(new RegExp("^[0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}$"))
    .required(),
  foto1: Joi.string().required(),
  filegiro: Joi.string().required(),
  //foto1: Joi.optional(),
  //filegiro: Joi.optional(),
  filegiro2: Joi.optional(),
  fontefbryd: Joi.optional(),
  dataInserimento: Joi.optional(),
  dataUscita: Joi.date().required(),
  stato: Joi.optional(),
};

export function validaUscita(uscita) {
  const optionJoi = { abortEarly: false };
  const result = Joi.validate(uscita, schemaUscita, optionJoi);
  if (!result.error) return null;
  console.log(result.error.details);
  return fixArrayErrori(result);
}

export function validaCampoUscita(campo, valore) {
  const obj = { [campo]: valore };
  const schema = { [campo]: schemaUscita[campo] };
  const result = Joi.validate(obj, schema);
  if (!result.error) return null;
  return fixArrayErrori(result);
}

function fixArrayErrori(result) {
  const errors = {};

  for (let item of result.error.details) {
    errors[item.path[0]] = true;
  }
  return errors;
}
