import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import Box from "@material-ui/core/Box";
import SelectLingua from "./SelectLingua";

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <Box mb={5} display="flex" alignItems="center" justifyContent="center">
          <SelectLingua
            onCambioLingua={this.props.onCambioLingua}
            lingua={this.props.lingua}
          ></SelectLingua>
        </Box>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Copyright © "}
          <Link color="inherit" href="https://material-ui.com/">
            Formula Bici
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
        <Box mt={5} display="flex" alignItems="center" justifyContent="center">
          <img
            alt="fbrydlogo"
            align="center"
            src="/img/rideyourdreamlogo.jpg"
          ></img>
        </Box>
      </div>
    );
  }
}

export default Footer;
