import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Footer from "./Footer";
import { verificaUtente } from "../services/users";
import Cookies from "universal-cookie";
import { FormattedMessage } from "react-intl";
import { CircularProgress } from "@material-ui/core";

class Login extends Component {
  state = {
    error: "",
    registrationcodeErr: false,
    loading: true,
  };

  componentWillUnmount() {}

  componentDidMount() {
    //se attivo il cookie ritorno subito autenticato
    const cookies = new Cookies();
    if (cookies.get("sessid")) {
      //console.log(cookies.get("sessid"));
      const promise = verificaUtente(cookies.get("sessid"), false);
      promise
        .then((user) => {
          //console.log("r", user);
          this.props.onAutenticato(user);
          this.setState({ loading: false });
        })
        .catch((error) => {
          //console.log("e", error);
          this.setState({
            error,
            registrationcodeErr: true,
            loading: false,
          });
        });
    } else {
      this.setState({ loading: false });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    let registrationcode = e.target.registrationcode.value;
    let rememberme = e.target.rememberme.checked;

    if (registrationcode === "") {
      this.setState({
        error: <FormattedMessage id="login.error1"></FormattedMessage>,
        registrationcodeErr: true,
      });
      return;
    }

    //verifica utente
    const promise = verificaUtente(registrationcode, rememberme);
    promise
      .then((user) => {
        console.log("r", user);
        this.props.onAutenticato(user, rememberme);
      })
      .catch((err) => {
        console.log("e", err);
        this.setState({
          error: <FormattedMessage id={err}></FormattedMessage>,
          registrationcodeErr: true,
        });
      });
  }
  /*
  autenticaUtente(user) {
    this.props.onAutenticato(user);
  }
*/

  render() {
    return this.state.loading ? (
      <CircularProgress></CircularProgress>
    ) : (
      <Grid container component="main" style={style.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} style={style.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div style={style.paper}>
            <Typography component="h1" variant="h5">
              Formula Bici RideYourDream
            </Typography>
            {this.state.error ? (
              <Alert style={style.alert} severity="error">
                {this.state.error}
              </Alert>
            ) : null}
            <form
              style={style.form}
              noValidate
              onSubmit={(e) => this.onSubmit(e)}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="registrationcode"
                label={<FormattedMessage id="login.registrationcode" />}
                name="registrationcode"
                //defaultValue="AL543577523254"
                //defaultValue="MI543577523606"
                autoFocus
                error={this.state.registrationIdErr ? true : null}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="rememberme"
                    color="primary"
                    name="rememberme"
                    id="rememberme"
                    checked
                  />
                }
                label={<FormattedMessage id="login.rememberme" />}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={style.submit}
              >
                <FormattedMessage id="login.login" />
              </Button>
              <Box mt={5}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  <FormattedMessage id="login.help" />
                </Typography>
              </Box>
              <Box mt={5}>
                <Footer
                  onCambioLingua={(lingua) => this.props.onCambioLingua(lingua)}
                  lingua={this.props.lingua}
                ></Footer>
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const style = {
  image: {
    backgroundImage: "url('./img/fondo.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundColor: "grey",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  root: {
    height: "100vh",
  },
  alert: {
    marginTop: "5%",
  },
  paper: {
    margin: "5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "5%",
  },
  submit: {
    marginTop: "5%",
  },
};

export default Login;
