import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { FormattedMessage } from "react-intl";

const TempoUscita = (props) => {
  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <DatePicker
        style={{ width: "100%" }}
        ampm={false}
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        error={props.error}
        invalidDateMessage={
          <FormattedMessage id="module.dataerrata"></FormattedMessage>
        }
        label={<FormattedMessage id="module.datauscita"></FormattedMessage>}
        value={props.value}
        onChange={(data) => props.onCambioData(data)}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TempoUscita;
