import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { db, storage } from "../../services/firebase";

const StatoDiploma = (props) => {
  const [loading, setLoading] = useState(true);
  const [diploma, setDiploma] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // Update the document title using the browser API
    //console.log("eff", props.iduscita);

    db.collection("uscite")
      .doc(props.iduscita)
      .get()
      .then((doc) => {
        let uscita = doc.data();
        //CERCO DI caricare il diploma se l'uscita è confermata
        if (uscita.stato === "confermata") {
          const nomefile = `/diplomi/${uscita.user.registrationcode}/${uscita.granfondo.nome}.pdf`;
          //console.log(nomefile);
          storage
            .ref(nomefile)
            .getDownloadURL()
            .then((url) => {
              //console.log("Got download url: ", url);
              setDiploma(url);
              setLoading(false);
            })
            .catch((err) => {
              setError(err.message);
              setLoading(false);
            });
        } else {
          setError("Uscita non confermata");
          setLoading(false);
        }
      });
  });

  return loading ? (
    <CircularProgress></CircularProgress>
  ) : error ? (
    <p style={{ color: "red" }}>{error}</p>
  ) : (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a target="_blank" href={diploma}>
      Scarica Ora
    </a>
  );
};

export default StatoDiploma;
