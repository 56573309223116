import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { FormattedDate } from "react-intl";
import { Link } from "react-router-dom";
import StatoDiploma from "./StatoDiploma";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function TabellaUscite(props) {
  const classes = useStyles();
  const { uscite } = props;

  return (
    <TableContainer mt={5} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>id uscita</TableCell>
            <TableCell>Utente</TableCell>
            <TableCell>Granfondo</TableCell>
            <TableCell align="right">Km</TableCell>
            <TableCell align="right">DSL+</TableCell>
            <TableCell align="right">DataCaricamento</TableCell>
            <TableCell align="right">Stato</TableCell>
            <TableCell align="right">Diploma</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {uscite.map((uscita, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {uscita.id}
              </TableCell>
              <TableCell component="th" scope="row">
                {uscita.user.nome} {uscita.user.cognome}
              </TableCell>
              <TableCell component="th" scope="row">
                {uscita.granfondo.nome}
              </TableCell>
              <TableCell align="right">{uscita.distanza}</TableCell>
              <TableCell align="right">{uscita.dislivello}</TableCell>
              <TableCell align="right">
                <FormattedDate
                  day="numeric"
                  month="long"
                  year="numeric"
                  hour="numeric"
                  minute="numeric"
                  value={
                    uscita.dataInserimento
                      ? uscita.dataInserimento.seconds * 1000
                      : null
                  }
                ></FormattedDate>
              </TableCell>
              <TableCell align="right">{uscita.stato}</TableCell>
              <TableCell align="right">
                <StatoDiploma iduscita={uscita.id}></StatoDiploma>
              </TableCell>
              <TableCell align="right">
                <Link to={`/admin/uscita/dettaglio/${uscita.id}`}>
                  Dettagli
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
