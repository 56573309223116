import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import { storage } from "../services/firebase";

import Button from "@material-ui/core/Button";

class FileUpload extends Component {
  constructor(props) {
    super();
    this.state = {
      file: {},
      urlfile: "",
      tipofile: "",
      progress: 0,
    };
  }

  changeFile(e, id) {
    let file = e.target.files[0];
    this.setState({ file });
  }

  //gestione caricamento file
  uploadFile(e, id) {
    const { file } = this.state;
    //console.log("upload", file);
    const fileUpload = storage
      .ref(`upload/${this.props.user.registrationcode}/${file.name}`)
      .put(file);

    fileUpload.on(
      "state_changed",
      (snapshot) => {
        //progress
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({ progress });
      },
      (err) => {
        //error
        console.log(err);
      },
      () => {
        //caricamento completato
        storage
          .ref(`upload/${this.props.user.registrationcode}/${file.name}`)
          .getDownloadURL()
          .then((urlfile) => {
            this.setState({
              urlfile,
            });
            this.props.onFileUploaded(this.props.id, urlfile);
          });
        //imposto tipo file per controllo
        this.setState({ tipofile: file.type });
      }
    );
  }

  renderProgress() {
    if (this.state.progress > 0) {
      return (
        <LinearProgress
          variant="determinate"
          value={this.state.progress}
          color="primary"
        />
      );
    } else {
      return "";
    }
  }

  isFileImage(filetype) {
    return filetype && filetype.split("/")[0] === "image";
  }

  renderRisultato() {
    if (this.isFileImage(this.state.tipofile)) {
      return (
        <img
          style={{ width: "150px", height: "auto", maxHeight: "150px" }}
          src={this.state.urlfile}
          alt=""
        ></img>
      );
    } else {
      return <FormattedMessage id="module.uploadcompleted"></FormattedMessage>;
    }
  }

  render() {
    return (
      <Grid container alignItems="center" alignContent="center">
        <Grid item xs={12} sm={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            required={this.props.required ? true : false}
            id={this.props.id}
            name={this.props.id}
            error={this.props.error}
            type="file"
            label={
              <FormattedMessage
                id={"module." + this.props.id}
              ></FormattedMessage>
            }
            fullWidth
            onChange={(e) => this.changeFile(e, this.props.id)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => this.uploadFile(e, this.props.id)}
          >
            <FormattedMessage id="module.upload"></FormattedMessage>
          </Button>
        </Grid>
        <Grid item xs={12} sm={3}>
          {this.state.urlfile ? this.renderRisultato() : this.renderProgress()}
        </Grid>
      </Grid>
    );
  }
}

export default FileUpload;
