import firebase from "firebase";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA9ocgjAk14Q3r5gfb2udUeA85eCwbcAm4",
  authDomain: "rideyourdreamfb.firebaseapp.com",
  databaseURL: "https://rideyourdreamfb.firebaseio.com",
  projectId: "rideyourdreamfb",
  storageBucket: "rideyourdreamfb.appspot.com",
  messagingSenderId: "319212949094",
  appId: "1:319212949094:web:9b802046737dbc56b267a7",
  measurementId: "G-Y0MJJDJEJ5",
};

firebase.initializeApp(firebaseConfig);

export const fb = firebase;
export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();

//per lo sviluppo!!
if (window.location.hostname === "localhost") {
  console.log("localhost detected");
  db.settings({
    host: "localhost:8080",
    ssl: false,
  });
}
