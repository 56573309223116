import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage } from "react-intl";
import { db, storage } from "../services/firebase";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import { FacebookProvider, ShareButton } from "react-facebook";
import { FormattedDate } from "react-intl";

class LeMieGf extends Component {
  constructor(props) {
    super();
    this.state = {
      uscite: [],
    };
  }

  componentDidMount() {
    //estraggo tutte le uscite
    db.collection("uscite")
      .where("user.registrationcode", "==", this.props.user.registrationcode)
      .orderBy("dataInserimento", "desc")
      .get()
      .then((snapshot) => {
        let uscite = [];
        snapshot.forEach((doc) => {
          let uscita = doc.data();
          uscita.id = doc.id;
          uscite.push(uscita);
        });
        this.setState({ uscite });
      });
  }

  async getDiploma(utente, nomegf) {
    const nomefile = `/diplomi/${utente}/${nomegf}.pdf`;
    console.log(nomefile);
    storage
      .ref(nomefile)
      .getDownloadURL()
      .then((url) => {
        //console.log("Got download url: ", url);
        return url;
      });
  }

  render() {
    return this.state.uscite.length === 0 ? (
      <main style={style.layout}>
        <Paper style={style.paper}>
          <FormattedMessage id="lemiegf.nogf"></FormattedMessage>
        </Paper>
      </main>
    ) : (
      <main style={style.layout}>
        <Paper style={style.paper}>
          <Typography component="h1" variant="h4" align="center">
            <FormattedMessage id="lemiegf.title"></FormattedMessage>
          </Typography>
          <Box m={5}></Box>

          <Grid container spacing={4} mt={4}>
            {this.state.uscite.map((uscita) => (
              <Grid key={uscita.id} id={uscita.id} item xs={12} sm={6} md={6}>
                <Card style={style.card}>
                  <CardMedia
                    style={style.cardMedia}
                    image={uscita.foto1}
                    alt={uscita.id}
                    title="Image title"
                  />
                  <CardContent style={style.cardContent}>
                    <Typography mb={3} gutterBottom variant="h5" component="h2">
                      {uscita.granfondo.nome}
                    </Typography>
                    <Box m={2}></Box>
                    <Grid mt={2} container spacing={2}>
                      <Grid align="left" item xs={4}>
                        <FormattedMessage id="lemiegf.distanza"></FormattedMessage>
                      </Grid>
                      <Grid item align="right" xs={8}>
                        <Chip color="primary" label={uscita.distanza + " Km"} />
                      </Grid>
                      <Grid align="left" item xs={4}>
                        <FormattedMessage id="lemiegf.dsl"></FormattedMessage>
                      </Grid>
                      <Grid item align="right" xs={8}>
                        <Chip
                          color="primary"
                          label={uscita.dislivello + " mt"}
                        />
                      </Grid>
                      <Grid align="left" item xs={4}>
                        <FormattedMessage id="lemiegf.localita"></FormattedMessage>
                      </Grid>
                      <Grid item align="right" xs={8}>
                        <Chip
                          color="primary"
                          label={uscita.localitapartenza.description}
                        />
                      </Grid>
                      <Grid align="left" item xs={4}>
                        <FormattedMessage id="lemiegf.datauscita"></FormattedMessage>
                      </Grid>
                      <Grid item align="right" xs={8}>
                        <Chip
                          color="primary"
                          label={
                            uscita.dataUscita ? (
                              <FormattedDate
                                value={uscita.dataUscita.seconds * 1000}
                              ></FormattedDate>
                            ) : (
                              ""
                            )
                          }
                        />
                      </Grid>
                      <Grid align="left" item xs={4}>
                        <FormattedMessage id="lemiegf.tempo"></FormattedMessage>
                      </Grid>
                      <Grid item align="right" xs={8}>
                        <Chip color="primary" label={uscita.tempo} />
                      </Grid>
                      <Grid align="left" item xs={4}>
                        <FormattedMessage id="lemiegf.stato"></FormattedMessage>
                      </Grid>
                      <Grid item align="right" xs={8}>
                        {uscita.stato === "nonconfermata" ? (
                          <Chip
                            color="secondary"
                            label={
                              <FormattedMessage id="lemiegf.nonconfermata"></FormattedMessage>
                            }
                          ></Chip>
                        ) : (
                          <Chip
                            color="primary"
                            label={
                              <FormattedMessage id="lemiegf.confermata"></FormattedMessage>
                            }
                          ></Chip>
                        )}
                      </Grid>
                      <Grid align="left" item xs={4}>
                        <FormattedMessage id="lemiegf.diploma"></FormattedMessage>
                      </Grid>
                      <Grid item align="right" xs={8}>
                        {uscita.diploma ? (
                          <a href={uscita.diploma}>Scarica Ora</a>
                        ) : (
                          <FormattedMessage id="lemiegf.diplomand"></FormattedMessage>
                        )}
                      </Grid>
                    </Grid>
                    <Box mt={5}>
                      <img
                        alt="fbrydlogo"
                        style={style.logogf}
                        align="center"
                        src={"/img/loghigf/" + uscita.granfondo.logo}
                      ></img>
                    </Box>
                  </CardContent>
                  <CardActions>
                    {uscita.stato === "confermata" ? (
                      <div style={style.buttonFB}>
                        <FacebookProvider appId="268128780906823">
                          <ShareButton
                            href={
                              "https://europe-west3-rideyourdreamfb.cloudfunctions.net/condividiUscita?iduscita=" +
                              uscita.id
                            }
                          >
                            <Button variant="contained" color="primary">
                              <FormattedMessage id="lemiegf.condividi"></FormattedMessage>
                            </Button>
                          </ShareButton>
                        </FacebookProvider>
                      </div>
                    ) : (
                      <strong>
                        Attendi la conferma dell'uscita per poterla condividere
                        su Facebook
                      </strong>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </main>
    );
  }
}

export default LeMieGf;

const style = {
  buttonFB: {
    margin: "0 auto",
  },
  logogf: {
    textAlign: "center",
    width: "60%",
  },
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: "5%",
    marginRight: "5%",
  },
  paper: {
    marginTop: "5%",
    marginBottom: "5%",
    padding: "5%",
  },
  cardGrid: {
    paddingTop: "5%",
    paddingBottom: "5%",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
    textAlign: "center",
  },
};
