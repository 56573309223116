import React, { Component } from "react";
import TabellaUscite from "./TabellaUscite";
import { db } from "../../services/firebase";

class Uscite extends Component {
  state = { uscite: [] };
  componentDidMount() {
    console.log("mount", this.props.uscite);

    if (this.props.uscite === "daconfermare") {
      //carico le uscite
      let usciteQ = db
        .collection("uscite")
        .where("stato", "==", "nonconfermata")
        .orderBy("dataInserimento", "desc");
      let uscite = [];
      usciteQ.get().then((querySnapshot) => {
        if (querySnapshot.empty) {
          console.log("nessuna uscita");
          this.setState({ loading: false });
        }
        for (const doc of querySnapshot.docs) {
          //console.log(doc.data());
          let uscita = doc.data();
          uscita.id = doc.id;
          uscite.push(uscita);
        }
        this.setState({ uscite });
        this.setState({ loading: false });
      });
    } else {
      //carico le uscite
      let usciteQ = db.collection("uscite").orderBy("dataInserimento", "desc");
      let uscite = [];
      usciteQ.get().then((querySnapshot) => {
        if (querySnapshot.empty) {
          console.log("nessuna uscita");
          this.setState({ loading: false });
        }
        for (const doc of querySnapshot.docs) {
          //console.log(doc.data());
          let uscita = doc.data();
          uscita.id = doc.id;
          uscite.push(uscita);
        }
        this.setState({ uscite });
        this.setState({ loading: false });
      });
    }
  }

  render() {
    return <TabellaUscite uscite={this.state.uscite} />;
  }
}

export default Uscite;
